import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import ImageHeader from "../images/podcast-submission-header.jpeg"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionTitle from "../components/common/SectionTitle"
import SEO from "../components/seo"

const StyledFormP = styled.div`
  max-width: 1000px;
  padding: 0.5rem;
  margin: 0 auto;
`
const StyledForm = styled.div`
  max-width: 800px;
  margin: 0 auto;
  /* border: 2px solid black; */

  button {
    background-color: #be1e2d;
    border: none;
    color: white;
    padding: 0.5rem;
    font-size: 1em;
    margin: 1rem 0;
  }

  input {
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1em;
  }

  select {
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1em;
  }

  textarea {
    width: 95%;
    height: 150px;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1.3em;
  }

  p,
  h3,
  h4 {
    padding: 0.5rem;
    margin: 0;
  }
`

const ShowSubmissionPage = () => {
  return (
    <>
      <SEO
        title="show submission"
        description="Submit your Geek / Nerd Podcast to be played live on air, or have it added to the Geek Life Radio podcast directory."
      />
      <Layout>
        <PageImageHeader imageHeader={ImageHeader} />
        <SectionTitle title="Show Submisssion" />
        <StyledFormP>
          <p>
            Thank you for considering adding your original content to Geek Life
            Radio. Before submitting your show please take the time to read
            through our guild lines.
          </p>

          <p>
            Once your show is submitted, it will be juried by our panel of
            judges. We then make a decision where your show fits within our
            network.
          </p>

          <p>
            Things we take into consideration while reviewing shows are show
            category, length of show, how many episodes currently available,
            quality of audio, and quality of content.
          </p>

          <p>There are two possible ways your show is added to our network</p>
          <ol>
            <li>Podcast Directory</li>
            <li>Scheduled Time Slot</li>
          </ol>

          <h2>Podcast Directory Only</h2>
          <p>
            All submitted shows, so long as they meet the minimum requirements
            will be added to the directory. Shows in our directory receive the
            following perks.
          </p>
          <ul>
            <li>Directory listing sorted by category</li>
            <li>
              Dedicated web page located at
              geekliferadio.com/podcast/name-of-podcast
            </li>
            <ul>
              <li>Includes last five episodes of your show</li>
              <li>About and contact section.</li>
              <li>Social media links</li>
              <li>Links to everywhere people can subscribe to your show</li>
            </ul>
            <li>
              Audio advertisement sent it by you that will be played live in
              rotation on the station
            </li>
            <li>
              Invite to our discord with Geek Life Radio Staff and other content
              creators.
            </li>
          </ul>
          <h2>Scheduled Time Slot</h2>
          <p>
            You will be given a 1-3 hour weekly time slot (based upon the
            average length of your show) . We will work with you on a time and
            day that best fits your schedule. This time is yours to do with as
            you would like. Shows in our Scheduled time slot receive the
            following perks.
          </p>
          <ul>
            <li>All the Podcast Directory Only perks</li>
            <li>Ability to do your show live on the air</li>
            <li>Listing in our calendar</li>
            <li>Weekly social media ads through all of our channels</li>
            <li>
              Ability to play and schedule past pre-recorded episodes that play
              in your time slot.
            </li>
            <li>
              Add as many advertisements for your show as you would like (One is
              required that specifies your time slot.)
            </li>
            <li>
              Add as many advertisements for your show as you would like (One is
              required that specifies your time slot.)
            </li>
          </ul>

          <p>
            If there are any questions hit us up at: hello@geekliferadio.com
          </p>

          {/* <p>
            Thank you for considering Geek Life Radio to showcase your original
            content. We offer a number of ways to exhibit your show.
          </p>
          <h4>Live recorded show</h4>
          <p>
            Upload your most recent podcast episode to be played live on air in
            a chosen time slot weekly.
          </p>{" "}
          <h4>Live broadcasted show</h4>{" "}
          <p>
            This feature will be coming soon. Show creators will soon have the
            ability to broadcast their show live on air.
          </p>{" "}
          <h4>Directory</h4>
          <p>
            All accepted shows are added to our podcast directory. Shows in our
            directory have a dedicated page with links to your social media,
            subscription services, and the ability to listen to previous
            episodes.
          </p>
          <h4>Other advantages</h4>{" "}
          <p>
            Shows added to our directory have the option to upload both radio
            and banner advertisements to Geek Life Radio. Ads are free.
            Directory shows are considered first when filling available live
            show openings.
          </p>{" "}
          <p>
            All submissions are juried. We then decide where your show currently
            fits within our network. Content, genre, and live show availability
            are some of the criteria we explore when considering placement. If
            not chosen for a live slot initially you will be placed in a que for
            consideration as openings become available.
          </p>
          <p> Please allow 72 hours for jurying and feedback.</p>{" "}
          <p>We look forward to listening to your show!</p> */}
        </StyledFormP>
        <StyledForm>
          <form
            method="POST"
            name="show-submission"
            data-netlify="true"
            action="/show-submission-success"
          >
            <input type="hidden" name="form-name" value="show-submission" />
            <h3>Contact Info</h3>
            <input type="text" name="name" placeholder="name *" required />
            <input
              type="email"
              name="contact-email"
              placeholder="contact email *"
              required
            />
            <h3>Show Info</h3>
            <input
              type="text"
              name="show-name"
              placeholder="show name *"
              required
            />
            <input type="text" name="hosts" placeholder="hosts" />
            <textarea
              name="description"
              placeholder="show description (max 250 char.) *"
              required
              maxLength="250"
            />
            <input
              type="text"
              name="feed-url"
              placeholder="podcast feed url *"
              required
            />
            <select name="categories" required>
              <option selected disabled>
                Select Category
              </option>
              <option name="video-games" value="video-games">
                Video Games
              </option>
              <option name="table-top-games" value="table-top-games">
                Table Top Games
              </option>
              <option name="tech" value="tech">
                Technology
              </option>
              <option name="comics" value="comics">
                Comics
              </option>
              <option name="tv-movies" value="tv-movies">
                TV & Movies
              </option>
              <option name="pro-wrestling" value="pro-wrestling">
                Pro Wrestling
              </option>

              <option name="pop-culture" value="pop-culture">
                Pop Culture
              </option>
            </select>
            <h4>Album Art</h4>
            <p>Best size is 350px x 350px</p>
            <input
              style={{ border: "none" }}
              name="file-art-upload"
              type="file"
            />
            <h4>or</h4>
            <input type="text" name="art-url" placeholder="Album Art URL" />
            <h4>Show frequency</h4>
            <p>How often does your show air? weekly, bi-weekliy ect...</p>
            <input
              type="text"
              name="show-frequency"
              placeholder="show frequency * "
              required
            />
            <h4>Show Duration</h4>
            <p>How long does your show run? 1 hour 2hour etc.</p>
            <input
              required
              type="text"
              name="show-duration"
              placeholder="show duration *"
            />
            <h3>Placement</h3>
            <p>Select how your would like your show added</p>
            <select required name="placement">
              <option disabled selected>
                Select Placement *
              </option>
              <option name="live-recorded" value="live-recorded">
                Live recorded show
              </option>
              <option name="directory-only" value="directory-only ">
                Directory Only
              </option>
            </select>
            <h4>
              Are you interested in brodcasting your show live when available?
            </h4>
            <select name="Interested-live-show" required>
              <option disabled selected>
                Interested ?
              </option>
              <option name="yes" value="yes">
                Yes
              </option>
              <option name="no" value="no">
                No
              </option>
            </select>
            <h3>Social and Subscription Services</h3>
            <p>
              Completed fields will be added to your show page. Leave blank any
              that do not apply
            </p>
            <h3>Social</h3>
            <input type="text" name="website" placeholder="Website URL" />
            <input type="text" name="public-email" placeholder="Public Email" />
            <input type="text" name="twitter" placeholder="Twitter" />
            <input type="text" name="facebook" placeholder="Facebook" />
            <input type="text" name="instagram" placeholder="Instagram" />
            <input type="text" name="pinterest" placeholder="Pinterest" />
            <input type="text" name="tumblr" placeholder="Tumblr" />
            <input type="text" name="linkedin" placeholder="LinkedIn" />
            <input type="text" name="youtube" placeholder="You Tube" />
            <input type="text" name="twitch" placeholder="Twitch" />
            <input type="text" name="patreon" placeholder="Patreon" />
            <h3>Subscription Services</h3>
            <input
              type="text"
              name="apple-podcast"
              placeholder="Apple Podcast"
            />
            <input
              type="text"
              name="google-podcast"
              placeholder="Google Podcast"
            />
            <input type="text" name="spotify" placeholder="Spotify" />
            <input type="text" name="tunein" placeholder="TuneIn" />
            <input type="text" name="android" placeholder="Android" />
            <input type="text" name="stitcher" placeholder="Stitcher" />
            <input type="text" name="iHeart" placeholder="iHeartRADIO" />
            <input type="text" name="pandora" placeholder="Pandora" />
            <input type="text" name="pocketcast" placeholder="Pocket Cast" />
            <input type="text" name="overcast" placeholder="OverCast" />
            <button type="submit">Submit Show</button>
          </form>
        </StyledForm>
      </Layout>
    </>
  )
}
export default ShowSubmissionPage
